const div = document.getElementById("insertHere");
const h1 = document.createElement("h1");

h1.innerText = "Using TypeScript and HTML";
div.appendChild(h1)

const first_h2 = document.createElement("h2");
first_h2.innerText = "About Me";
first_h2.id = "section";
div.appendChild(first_h2);

let personalInfo = {
    name: "Fortune Dessin",
    age: 22,
    zodiac:[
        
            ["Capricorn Sun","Strategic, Observant, Pragmatic"],
        
            ["Scorpio Moon","Intense, Magnetic, Deep"],
        
            ["Sagittarius Asc" , "Bold, Enthusiastic, Positive"]
    ],
    plantParent:true
};
let detail = personalInfo as any;

const table1 = document.createElement("table");

for(let factType in personalInfo){
    const row = document.createElement("tr");
    const data1 = document.createElement("td");
    const data2 = document.createElement("td");
    data1.innerText = factType;
	row.appendChild(data1);
 
    if(personalInfo[factType] instanceof Array) {
        const ul = document.createElement("ul");

        for(let item in personalInfo[factType]){
            const li = document.createElement("li");
            li.setAttribute("class", ".zodiac")
            const txt = document.createTextNode(detail[factType][item][0]);
            li.appendChild(txt);

            const descLi = document.createElement("li");
            descLi.setAttribute("class", "hide");
            console.log("detail[factType][item][0] is:" + detail[factType][item][0]);
            console.log("detail[factType][item][1] is:" + detail[factType][item][1])
            descLi.innerText = detail[factType][item][1];
            
            li.appendChild(descLi);
            ul.appendChild(li);
            //ul.appendChild(li);
            //ul.appendChild(descLi);
        
        }
        data2.appendChild(ul);
    }
    else {
    const item = document.createTextNode(detail[factType])
    data2.appendChild(item);
    }

    row.appendChild(data2);
    table1.appendChild(row);
}

div.appendChild(table1);

type Course = {
    code: string
    name: string
    credits: number
}

const second_h2 = document.createElement("h2");
second_h2.innerText = "Courses in Progress";
second_h2.id = "section";
div.appendChild(second_h2);

type OngoingCourse = Course & {
    classTimes: Array<string>
}

const onGoing: Array<OngoingCourse> = [
    {
        code:   "CIS371-01",
        name: "Web Application Development",
        credits: 3,
        classTimes: ["Mon 14:00 - 14:50","Wed 14:00 - 14:50", "Fri: 14:00 - 14:50"]
    },
    {
        code:   "CIS343-02",
        name: "Structure of Programming Languages",
        credits: 3,
        classTimes: ["Mon 18:00 - 20:50"]
    },
    {
        code:   "CIS263-02",
        name: "Data Structures & Algorithms",
        credits: 3,
        classTimes: ["T 13:00 - 14:15","Th 13:00 - 14:15"]
    },
   
]

const ol = document.createElement("ol");
let myCourse = onGoing as any;
for(let course in onGoing){
    const classLi = document.createElement("li");
    const classTxt = document.createTextNode(myCourse[course].code + " " +
        myCourse[course].name + ' (' +myCourse[course].credits + ' credits)');
    const ul = document.createElement("ul");

    for(let times in myCourse[course].classTimes){
        const timesLi = document.createElement("li");
        timesLi.innerText = myCourse[course].classTimes[times];
        ul.appendChild(timesLi);
    }
    classLi.appendChild(classTxt);
    ol.appendChild(classLi);
    ol.appendChild(ul);
}

div.appendChild(ol);


const third_h2 = document.createElement("h2");
third_h2.id = "section";

const a = document.createElement("a");
a.setAttribute("href", "https://www.gvsu.edu/catalog/2021-2022/course/");
a.innerText = "Courses Completed";
third_h2.appendChild(a);

div.appendChild(third_h2);
type CompletedCourse = Course & {
    semesterCompleted: string
}
const completed: Array<CompletedCourse> = [
{
    code: "WRT150",
    name: "Strategies in Writing",
    credits: 4,
    semesterCompleted: "Fall 2019"
},
{
    code: "CIS162",
    name: "Computer Science I",
    credits: 4,
    semesterCompleted: "Fall 2019"
},
{
    code: "CHM115",
    name: "Principles of Chemistry I",
    credits: 4,
    semesterCompleted: "Winter 2020"
},
{
    code: "CIS163",
    name: "Computer Science II",
    credits: 4,
    semesterCompleted: "Winter 2020"
},
{
    code: "MTH225",
    name: "Discrete Structures: CS I",
    credits: 3,
    semesterCompleted: "Winter 2020"
},
{
    code: "CIS241",
    name: "System-Level Programming & Utilities",
    credits: 3,
    semesterCompleted: "Fall 2020"
},
{
    code: "MTH325",
    name: "Discrete Structures: CS II",
    credits: 3,
    semesterCompleted: "Fall 2020"
},
]

const completedTable = document.createElement("table");
const headerTr = document.createElement("tr");

const courseTh = document.createElement("th");
courseTh.innerText = "Course";
headerTr.appendChild(courseTh);

const creditTh = document.createElement("th");
creditTh.innerText = "Credit";
headerTr.appendChild(creditTh);

const completedTh = document.createElement("th");
completedTh.innerText = "Completed";
headerTr.appendChild(completedTh);
completedTable.appendChild(headerTr);

let totalCredits : number = 0;
for(let course in completed){
    let detail = completed[course] as any;
    const courseTr = document.createElement("tr");

    const nameTd = document.createElement("td");
    nameTd.innerText = completed[course].code + " " + completed[course].name;
    courseTr.appendChild(nameTd);

    for(let info in completed[course]){
        if(info != "name" && info != "code"){
            const td = document.createElement("td");
            td.setAttribute("attr", "right-align");
            const txt = document.createTextNode(detail[info]);
            td.appendChild(txt);
            courseTr.appendChild(td);
        }
    }
    
    totalCredits += completed[course].credits;
    
    completedTable.appendChild(courseTr);
}
const totalCreditsTr = document.createElement("tr");
const td1 = document.createElement("td");
td1.innerText = "Total Credits";
const td3 = document.createElement("td");
td3.innerText = " ";

const totalCreditsTd = document.createElement("td");
const txt = document.createTextNode(" " + totalCredits + " ");
totalCreditsTd.appendChild(txt);

totalCreditsTr.appendChild(td1);
totalCreditsTr.appendChild(totalCreditsTd);
totalCreditsTr.appendChild(td3);

completedTable.appendChild(totalCreditsTr);
div.appendChild(completedTable);


